export default {
    // Storages.vue
    'StorageList': 'Storage List',
    's3Storage': 's3 storage',
    'CopyStorageSpacePath': 'Copy storage space path',
    'ModifyStorageSpace': 'Modify storage space',
    'createModal': 'create',
    'create': 'create ',
    'CreateStorageSpace': 'Create storage space',
    'StorageSpaceName': 'Storage space name',
    'StorageType': 'Storage type',
    'SyncStorage': 'Sync Storage',
    'SyncRepository': 'Sync Repository',
    'LocalStorage': 'Local storage',
    'Note': 'Note',
    'NFSStorage': 'The default storage is NFS local directory storage.',
    'BucketNameDefinition': 'S3 Storage: The default bucket name is the storage space name. You can also customize the bucket name.',
    'unmodifiableNote': 'Note: The storage space name, storage type, and S3 bucket path cannot be modified once created.',
    'LocalPath': 'Local path',
    'S3Path': 'S3 path',
    'BucketName': 'Bucket name',
    'ParentDirectory': 'Parent directory',
    'Custom': 'Custom',
    'cancelCustom': 'cancel custom',
    'Administrator': 'Administrator',
    'SelectAdministrator': 'Please select Administrator',
    'user': 'user',
    'selectUser': 'Please select User',
    'Cancel': 'Cancel',
    'Confirm': 'Confirm',
    'StorageSpaceOperation': 'Modify or delete storage space',
    'SpaceNameRemain': 'The storage space name cannot be modified.',
    'BucketRemain': 'The storage type and S3 bucket cannot be changed.',
    'ImportantNote': 'Note (Please exercise caution!!)',
    'HaveAccess': 'The administrator/member list you select will have access to the storage space.',
    'RegularCleaning': 'Delete: Only storage configurations are deleted. The storage configurations are deleted periodically at 00:00 every day.',
    'IfForcedDeletion': 'The repository list cannot be recovered if it is forcibly deleted.',
    'Edit': 'Edit',
    'Delete': 'Delete',
    'ForcedDeletion': 'Forced deletion',
    'ConfirmDeletion1': 'Are you sure you want to delete the ',
    'ConfirmDeletion2': ' product warehouse? Please proceed with caution.',
    'WarehouseName': 'Warehouse name',
    'LogicalDeletion': 'Delete: Only the installation package is deleted logically.',
    'ProductWarehouse': 'Product warehouse',
    'Start': 'Start ',
    'yourFolib': 'your product library',
    'Will': 'A new product library will be',
    'Under': 'under',
    'StorageSpace': 'storage space',
    'TypeSelection': 'Type selection',
    'BasicInformation': 'Basic information',
    'RemoteConfiguration': 'Remote configuration',
    'CombinationConfiguration': 'Combination configuration',
    'ClickNext': 'Cannot be modified, please click Next',
    'HowToChoose': "Don't know how to choose?",
    'WarehouseTypeRemain': 'You cannot change the warehouse type in modified mode!',
    'IdentifyWarehouseType': 'Identify the type of warehouse you want to choose according to the icon and the type name issued!',
    'NextVersion': 'The next version update is coming soon',
    'Next': 'Next',
    'FillInInformation': 'OK, then fill in the basic information',
    'DockerType': 'You have selected the Docker repository type',
    'LayoutType': 'You have selected the {layout} repository type',
    'DifferentProcess': 'The process for configuring different warehouse policies is different',
    'KeywordPrompt': 'Do not appear warehouse type keywords',
    'Strategy': 'Strategy',
    'Local': 'Local',
    'Agent': 'Agent',
    'Combination': 'Combination',
    'VersioningStrategy': 'Versioning strategy',
    'WarehousePath': 'Warehouse path',
    'DistributedRemain': 'The current storage is distributed, and storage path definition is not supported',
    'ItemLimit': 'Item Size Limit (MB)',
    'RepositorySizeLimit': 'Repository Size Limit (TB)',
    'StorageSizeLimit': 'Storage Size Limit (TB)',
    'ServiceStatus': 'Service status',
    'OpenService': 'In Service',
    'ShutdownService': 'Out of Service',
    'RecycleBin': 'Recycle bin',
    'UploadDeploy': 'Upload and deploy',
    'UploadOverlay': 'Upload overlay',
    'DirectoryBrowsing': 'Directory browsing',
    'Allowed': 'Allowed',
    'NotAllowed': 'Not allowed',
    'Back': 'Back',
    'Complete': 'Complete ',
    'WarehouseConfig': 'Remote warehouse configuration',
    'RemoteLibraryAddress': 'You have selected the remote repository type, but also need to configure your remote library address, you can also open the local proxy to access the remote address',
    'RemoteAccessAddress': 'Remote access address',
    'AddressFormat': 'http://xxxx or https://xxxx',
    'Test': 'Test',
    'Username': 'Username',
    'AccessUser': 'User name for remote warehouse access',
    'AccessPassword': 'Remote warehouse access password',
    'TimedCheck': 'Timed check time (seconds)',
    'DefaultTime': 'Default 60 seconds',
    'InspectionMechanism': 'Inspection mechanism',
    'None': 'None',
    'Strict': 'Strict',
    'LogRecording': 'Log recording',
    'Advanced': 'Advanced',
    'LocalAgent': 'Local agent',
    'On': 'On',
    'Off': 'Off',
    'AutomaticLock': 'Automatic locking',
    'ChecksumCheck': 'Checksum check',
    'RemoteIndexDownload': 'Remote index download',
    'Download': 'Download',
    'NoDownload': 'Do not download',
    'ProxySetup': 'Proxy setup',
    'UseProxySettings': 'This feature can only be used if the current artifact library does not have access to the remote agent library',
    'Port': 'Port',
    'Protocol': 'Protocol',
    'NoUserName': 'proxy user name. None can be left blank',
    'Password': 'Password',
    'NoPassword': 'proxy user password. No parameter can be left blank',
    'CompositeWarehouse': 'Composite warehouse configuration',
    'WarehousePortfolio': 'You have selected a combination warehouse, which can be dragged from left to right to combine multiple warehouses.',
    'CustomizedStrategy': 'Configure custom policies for the warehouse',
    'TimingStrategy': 'The timing policy is used to set the related policies such as warehouse garbage cleaning and synchronization',
    'HaveSet': 'Have set',
    'ExpandSetting': 'Expand setting',
    'ExecuteOnce': 'Execute once',
    'LoopExecution': 'Loop execution',
    'ImmediateExecution': 'Immediate execution',
    'NoImmediateExecution': 'No immediate execution',
    'OtherParameters': 'Other parameters',
    'CompleteSetting': 'Complete policy setting',
    'EnterSpaceName': 'Please enter the storage space name',
    'SpaceNameLengthLimit': 'Length between 1 and 30 characters',
    'SpaceNameLimit': 'The storage space name should consist of uppercase and lowercase letters, numbers, and special characters (-.)',
    'SelectableRepository': 'Selectable artifact repository',
    'CompositeRepository': 'Composite Repository',
    'LicenseError': 'The License is incorrect',
    'CheckLicense': 'Please check if the License exists',
    'LicenseExpired': 'The License has expired',
    'AddWarehouseAfterRenewal': 'Please add the product warehouse after renewal',
    'CopySuccessful': 'Copy successfully',
    'UnknownError': 'Unknown error',
    'StorageNameExists': 'The storage name already exists',
    'FailedCreate': 'Failed to create',
    'OperationIncorrect': 'The operation is incorrect',
    'selectSpace': 'You should first select a storage space from the left and then create a new repository',
    'Success': 'Success',
    'FillInCronExpression': 'Please fill in the cron expression',
    'Failure': 'Failure',
    'FillInErrors': 'Fill in errors',
    'EnterRepository': 'Please enter the name of the repository',
    'RepositoryLengthLimit': 'Repository names are between 1 and 30 characters long',
    'RepositoryLimit': 'Repository names should be lowercase letters, numbers, special symbols (-_.) , cannot begin with _.',
    'WarehouseModified': 'The warehouse has been modified',
    'WarehouseAdded': 'The warehouse has been added',
    'RepositoryNameExists': 'Repository name already exists',
    'Deleted': 'Deleted',
    'ContentError': 'The content to be deleted is filled in incorrectly',
    'ConnectedFine': 'The remote repository is connected fine.',
    'ConnectedFailed': 'Remote repository connection failed',
    'ResponseStatusCode': 'Response status code',
    'warehouseTips': '(tips: Some repositories do not support browsing will also return 404, but it does not affect the build use, please check whether the remote repository is such a repository)',
    'WarehouseSetup': 'Warehouse setup',
    'PermissionSetting': 'Permission setting',
    'TimingPolicy': 'Timing policy',
    'FederatedRepository': 'Federated repository',
    'Scan': 'Scan',
    // LibView.vue
    'Details': 'Details',
    'Statistics': 'Statistics',
    'MakingHistory': 'Making history',
    'ProductPath': 'Product path',
    'CreationTime': 'Creation time',
    'LastTimeOfUse': 'Last time of use',
    'NumberOfDownloads': 'Number of downloads',
    'ProductSize': 'Product size',
    'CVENumber': 'CVE number',
    'VulnerabilityLevel': 'Vulnerability level',
    'CvssV2VulnerabilityLevel': 'CvssV2 vulnerability level',
    'CvssV3VulnerabilityLevel': 'CvssV3 vulnerability level',
    'CvssV2Score': 'CvssV2 score',
    'CvssV3Score': 'CvssV3 score',
    'ImportVersion': 'Import version',
    'VulnerabilityNumber': 'Vulnerability number',
    'IntroducingTime': 'Introducing time',
    'HighestVulnerabilityLevel': 'The highest vulnerability level',
    'RecommendedFixVersion': 'Recommended fix version',
    'Operation': 'Operation',
    'TheNumbers': 'Number',
    'String': 'String',
    'Text': 'Text',
    'OperationSuccessful': 'Operation successful',
    // Scanner.vue
    'ScannedPacketsNum': 'Total number of scanned packets',
    'InsteadOfScanningPacketsNum': 'Total number of packages skipped from scanning',
    'SuccessScannedPacketsNum': 'Number of packages successfully scanned',
    'FailedScansNUM': 'Number of packages failed to scan',
    'ScanDependenciesNum': 'Scan the number of dependencies',
    'VulnerablePackagesNum': 'Number of vulnerable packages',
    'VulnerabilitiesTotalNum': 'Total number of vulnerabilities',
    'sealedVulnerabilitiesNum': 'Number of sealed vulnerabilities',
    'Compare': 'Vulnerability distribution view for the past 7 days',
    'packagesNum': 'The number of packages is ',
    'unknown': ' unknown compared with last week',
    'unchanged': ' unchanged from last week',
    'dataCompared': "Here are the results of this week's data compared to last week's data (14 days).",
    'NewVulnerabilityPackage': 'New vulnerability package',
    'ScanForNewDependencies': 'Scan for new dependencies',
    'NewVulnerabilityDependency': 'New vulnerability dependency',
    'NewSealedVulnerabilitiesNum': 'Number of new sealed vulnerabilities',
    '30daysData': 'Data in the last 30 days',
    '30daysDataChat': 'This figure shows the data of the last 30 days, and the dates without new data are not shown',
    'VulnerabilitiesNum': 'Number of vulnerabilities',
    'WarehouseScanningSituation': 'Warehouse scanning situation',
    'PlatformVulnerability': 'Platform vulnerability',
    'DependingOnQuantity': 'Depending on quantity',
    'Warehouse': 'Warehouse',
    'TotalNumberOfPackets': 'Total number of packets',
    'NumberOfQuestions': 'Number of questions',
    'SecurityScore': 'Security score',
    'EventRecord':'Event record',
    'PromotionRecord':'Promotion record',
    'GitRepositoryAddr': 'Git repository Addr',
    'GitProviderCredential': 'Git private repository credentials',
    'GitUsername': 'Git repository username',
    'GitPassword': 'Git repository password/token',
    'GitProvider': 'Git Provider',
    'AddGitCredential': 'add Git repository Credential',
    'StorageOverview': 'Storage overview',
    'FilesSize':'Files size',
    'FilesCount':'Files count',
    'RepositoryCount': 'Repository count',
    'ArtifactsSize':'Artifacts size',
    'ArtifactsCount':'Artifacts count',
    'FoldersCount':'Folders count',
    'ItemsCount':'Items count',
    'StorageDeviceType': 'Storage device type',
    'StorageQuotaSize':'Storage quota size',
    'DataUpdateTime': 'Latest update time of data',
    'LastTenUpdates': 'Last 10 updates to the storage data view',
    'RepositoryList': 'Repository list',
    'ScanTime': 'Scan time',
    'RepositoryQuery': 'Please select repository query',
    'RepositoryInfo':'Repository info',
    'packageTypeQuery':'Select package type search',
    'StrategyTypeQuery':'Select strategy type search',
    'RepositoryNameQuery':'Enter the Repository Name search',
    'Loading':'Loading more',
    'Save':'save',
}
